import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Auth } from "aws-amplify";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Button, CircularProgress} from "@mui/material";

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  backgroundAlpha:0,
  // boxShadow: 24,
  outline: 'none',
  zIndex:99999999

};

export default function LoadingBar({ type }) {
  const [open, setOpen] = useState(true);


  return (
    <div>
      <Box sx={style}>
         <CircularProgress />
        </Box>
    </div>
  );
}
