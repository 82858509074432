import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {Box, ButtonBase, Chip, CircularProgress} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";

// import { useAuthenticator } from "@aws-amplify/ui-react";


import { AppLogo, RehrigLogo } from "../../assets";
//import { LicenseInfo } from '@mui/x-license';
import {
  qcBotItems,
  loadValidationItems,
  warehouseInsightItems,
  deliveryInsights,
} from "./consts/navListItems";
import { checkAccess } from "../../redux/Thunk/AuthThunk";
import {getCentralAdminId, getToken, getUserDetails, openNewURLAMI} from "../../redux/Thunk/WarehouseInsightsThunk"
import {
  setPageSubTitle,
  setVorReferenceNumber,
  setCustomers,
  setSelectedCustomer,
  setSelectedDistributionCenter,
  setLoadingPalletsData,
  setPalletsData,
  // setLoadingPalletsDetails,
  setPalletInferenceID,
  setPalletDetails,
  //setGoBackToPalletReview
  setStartRefresh
} from "../../redux/reducers/palletReview/PalletReviewReducer";
import {
  setPalletArchiveData,
  setLoadingPalletsArchiveData,
} from "../../redux/reducers/palletArchive/PalletArchiveReducer";

import {
  setStartDockDoorRefresh,
} from "../../redux/reducers/dockDoorControl/DockDoorControlReducer";

import {
  resetEmbedURL
} from "../../redux/reducers/warehouseInsights/WarehouseInsightsReducer"

import {SESSION_KEY, isMobile, isTabletDevice} from "../Common/helpers";
import Cookies from "js-cookie";
import {Constants} from "../../utils/Constants";
import ShowModal from "../Common/PermissionModal";
import {LicenseInfo} from "@mui/x-license";
import LoadingBar from "../Common/LoadingBar";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth - 45}px`,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function SideNavDrawer({
                         children,
                         checkAccessFromProps,
                         palletDetailsFromProps,
                         pageSubTitleFromProps,
                         setPageSubTitleFromProps,
                         setVorReferenceNumberFromProps,
                         setCustomersFromProps,
                         setSelectedCustomerFromProps,
                         setSelectedDistributionCenterFromProps,
                         setLoadingPalletsDataFromProps,
                         setPalletsDataFromProps,
                         setPalletInferenceIDFromProps,
                         setPalletDetailsFromProps,
                         setPalletArchiveDataFromProps,
                         setLoadingPalletsArchiveDataFromProps,
                         setStartRefreshFromProps,
                         setStartDockDoorRefreshFromProps,
                         setResetEmbedURLFromProps, getUserDetails,

                         setOpenNewURLAMI,
                       }) {
  const theme = useTheme();
  let navigate = useNavigate();
  let location = useLocation();
  const [open, setOpen] = useState(true);
  const [isQcBotCollapse, setIsQcBotCollapse] = useState(true);
  const [isLoadValidationCollapse, setIsLoadValidationCollapse] =
      useState(true);
  const [isWarehouseInsightsCollapse, setIsWarehouseInsightsCollapse] =
      useState(true);
  const [isDeliveryInsightsCollapse, setIsDeliveryeInsightsCollapse] =
      useState(true);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [pageGroupName, setPageGroupName] = useState("QC Bot");
  const [pageTitle, setPageTitle] = useState("Pallet Review");

  const [qcBotVisible,setQCBotVisible] = useState(false);
  const [lvVisible,setLVVisible] = useState(false);
  const [skVisible,setSKVisible] = useState(false);
  const [diVisible,setDIVisible] = useState(false);
  const [amVisible,setAMVisible] = useState(false);
  const [isUserPermisisons,setUserPermissions] = useState(true);
  const [isProgess,setProgress] = useState(false)

  // const { user } = useAuthenticator((context) => [context.user]);

  // console.log("user", user);

  // const AccessDenied = () => {
  //   setTimeout(() => {
  //localStorage.removeItem(SESSION_KEY);
  // Auth.signOut()
  //   .then(() => {
  //     window.location.reload();
  //   })
  //   .catch((err) => {
  //     console.log("Error on Logout:", err.message);
  //   });
  //   }, 3000);
  // };

  // const checkCentralUserAuth = async () => {
  //   let userData = await checkAccessFromProps();
  //   console.log("userData ", userData);
  //   if (userData?.has_access === true) {
  //     console.log("Access Granted");
  //   } else {
  //     AccessDenied();
  //   }
  // };

  // const checkUserAccess = () => {
  //   if (
  //     user?.attributes["custom:central_role_id"] === "1" ||
  //     user?.attributes["custom:central_role_id"] === "2"
  //   ) {
  //     console.log("Access Granted");
  //   } else {
  //     console.log("Access not Granted");
  //     checkCentralUserAuth();
  //   }
  // };

  // useEffect(() => {
  //   checkUserAccess();
  //   // eslint-disable-next-line
  // }, [user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  // console.log("palletDetailsFromProps", palletDetailsFromProps);
  const handleListItemClick = (e, item) => {
    // console.log("handleListItemClick", item.id, item.path, item.name);s
    setCookies();
    setPageTitle(item.name);
    setSelectedIndex(item.id);
    setPageGroupName(item.parentText);
    navigate(item.path);
    setPageSubTitleFromProps("");
    setVorReferenceNumberFromProps("");
    if(isTabletDevice()){
      handleDrawerClose();
    }
  };

  const handleQcBotCollapse = () => {
    setIsQcBotCollapse(!isQcBotCollapse);
  };

  const handleLoadValidationCollapse = () => {
    setIsLoadValidationCollapse(!isLoadValidationCollapse);
  };

  const handleGeneralFeaturesCollapse = () => {
    setIsWarehouseInsightsCollapse(!isWarehouseInsightsCollapse);
  };

  const handleDeliveryCollapse = () => {
    setIsDeliveryeInsightsCollapse(!isDeliveryInsightsCollapse);
  };

  // console.log("location", location.pathname);

  useEffect(()=>{
    // alert(window.screen.width);
    console.log("Side Navigation",location)
    //alert(isTabletDevice())
    //alert(navigator.platform)
    //alert(navigator.maxTouchPoints)
    LicenseInfo.setLicenseKey(process.env. REACT_APP_DATA_GRID_LICENSE_KEY);
    getLoginUserDetails();
  },[])

  let getLoginUserDetails = async ()=>{
    let locationPath = location.pathname;
    let isLogin = false;
    if(locationPath == '/' || locationPath == ''){
      isLogin = false;
    }else{
      isLogin = true;
    }
    setProgress(true);
    deliveryInsights.map((item,idx)=>{
      item.visible = false;
    })
    let response = await getUserDetails();
    //console.log(response);
    setProgress(false);
    setQCBotVisible(false);
    setDIVisible(false);
    setLVVisible(false);
    setUserPermissions(true);
    if(response && response.payload && response.payload.Data){
      let roleName = response.payload.Data.role_name;
      let applications = response.payload.Data.applications;
      if(roleName == Constants.RPC_ADMIN || roleName == Constants.RPC_READ_ONLY){
        navigateToQCBot(isLogin);
        setNavigationItems(applications,roleName);
      }else{
        if(applications){
          setNavigationItems(applications,roleName);
          showUserNavigationMenu(applications,isLogin);
        }
      }

    }
  }

  let setNavigationItems = (apps,roleName)=>{
    deliveryInsights.map((item,idx)=>{
      item.visible = false;
      if(roleName == Constants.RPC_ADMIN || roleName == Constants.RPC_READ_ONLY){
        item.visible = true;
      }else{
        if(apps.indexOf(6)>=0){
          if(item.text == "Assets"){
            item.visible = true;

          }
        }if(apps.indexOf(4)>=0){
          if (item.text != "Assets"){
            item.visible = true;
          }

        }
      }


    })
  }

  let navigateToAuditPage = ()=>{
    let inferenceId = "";
    let dcId = "";
    let pathArray = location.pathname.split("/");
    if(pathArray && pathArray.length>3) {
      inferenceId = pathArray[2];
      dcId = pathArray[3];
      Constants.isAuditLoaded = false;
      navigate(`/auditpallet/${inferenceId}/${dcId}`);
    }
  }

  let navigateToQCBot = (flag)=>{
    setQCBotVisible(true);
    setDIVisible(true);
    setLVVisible(true);
    setSKVisible(true);
    setPageGroupName("QC Bot");
    setPageTitle("Pallet Review");
    if(flag){
      if (location.pathname.includes("/auditpallet")){
        navigateToAuditPage();
      }else{
        navigate(location.pathname);
      }
    }else{
      navigate("/qcbot");
    }
  }

  let showUserNavigationMenu = (applications,flag)=>{
    let isExist = false;
    let isQcBotExist = false;
    let isLoadValidationExist = false;
    let isRouteDBExist = false;
    let isAssetDBExist = false;
    let isWHInsightExist = false;

    if(applications.indexOf(7)>=0 || applications.indexOf(6)>=0 || applications.indexOf(4)>=0 || applications.indexOf(2)>=0 || applications.indexOf(1)>=0){

      if(applications.indexOf(7)>=0){
        isQcBotExist = true;
        setQCBotVisible(true);
      }
      if(applications.indexOf(2)>=0){
        isLoadValidationExist = true;
        setLVVisible(true);
      }
      if(applications.indexOf(6)>=0 || applications.indexOf(4)>=0 ){
        setDIVisible(true);
        if(applications.indexOf(6)>=0){
          isRouteDBExist = true;
        }
        if(applications.indexOf(4)>=0){
          isAssetDBExist = true;
        }
      }
      if(applications.indexOf(1)>=0){
        isWHInsightExist = true;
        setSKVisible(true)
      }

      if(flag) {
        if (location.pathname.includes("qcbot") || location.pathname.includes("auditpallet") || location.pathname.includes("palletarchive")) {
          if (isQcBotExist) {
            isExist = true;
            if(location.pathname.includes("qcbot")){
              navigate("/qcbot");
            }else if(location.pathname.includes("auditpallet")){
              navigateToAuditPage();
            }else if(location.pathname.includes("palletarchive")){
              navigate("/palletarchive");
            }
          }
        }if(!isExist && location.pathname.includes("dockdoorcontrol")){
          if(isLoadValidationExist){
            isExist = true;
            navigate("/dockdoorcontrol");
          }
        }if(!isExist && (location.pathname.includes("operational-performance") || location.pathname.includes("picker-detail"))){
          if(isWHInsightExist){
            isExist = true;
            if(location.pathname.includes("operational-performance")){
              navigate("/operational-performance");
            }else if(location.pathname.includes("picker-detail")){
              navigate("/picker-detail");
            }
          }
        }if(!isExist && (location.pathname.includes("routestatus") || location.pathname.includes("lifts") || location.pathname.includes("casesperhour"))){
            if(isRouteDBExist){
              isExist = true;
              if(location.pathname.includes("routestatus")){
                navigate("/routestatus");
              }else if(location.pathname.includes("lifts")){
                navigate("/lifts");
              }else if(location.pathname.includes("casesperhour")){
                navigate("/casesperhour");
              }
            }
        }if(!isExist && location.pathname.includes("assets")){
          if(isAssetDBExist){
            if(location.pathname.includes("assets")){
              navigate("/assets");
            }
          }

        }
      }else{
        if(isQcBotExist){
          navigate("/qcbot");
        }else if (isLoadValidationExist){
          navigate("/dockdoorcontrol");
        }else if (isWHInsightExist){
          navigate("/operational-performance");
        }else if (isRouteDBExist){
          navigate("/routestatus");
        }else if (isAssetDBExist){
          navigate("/assets");
        }
      }

    }else if((applications.indexOf(5)>=0 || applications.indexOf(3)>=0)){
      setUserPermissions(false);
    }else{
      setUserPermissions(false);
    }
  }


  useEffect(() => {
    setCookies();
    if (location.pathname === "/qcbot") {
      Constants.SCREEN_NAME = Constants.PALLET_REVIEW;
      setSelectedIndex(1);
      setPageGroupName("QC Bot");
      setPageTitle("Pallet Review");
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
      setPageSubTitleFromProps("");
      console.log(pageSubTitleFromProps);
    } else if (location.pathname === "/dockdoorstatus") {
      setSelectedIndex(2);
      setPageGroupName("");
      setPageTitle("");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
     setResetEmbedURLFromProps()
    } else if (location.pathname === "/dockdoorcontrol") {
      setSelectedIndex(3);
      setPageGroupName("Load Validation");
      setPageTitle("Dock Door Controls");
      setStartRefreshFromProps(false)
      setResetEmbedURLFromProps()
    } else if (location.pathname === "/operational-performance") {
      setSelectedIndex(4);
      setPageGroupName("Warehouse Insights");
      setPageTitle("Operational Performance");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
    } else if (location.pathname === "/palletarchive") {
      Constants.SCREEN_NAME = Constants.PALLET_ARCHIVE;
      setSelectedIndex(5);
      setPageGroupName("QC Bot");
      setPageTitle("Pallet Archive");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
      setPageSubTitleFromProps("");
    } else if (location.pathname === "/picker-detail") {
      setSelectedIndex(6);
      setPageGroupName("Warehouse Insights");
      setPageTitle("Selector Details");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
    }  else if (location.pathname === "/routestatus") {
       setSelectedIndex(7);
       setPageGroupName("Delivery Insights");
       setPageTitle("Route Status");
       setStartRefreshFromProps(false)
       setStartDockDoorRefreshFromProps(false)
       setResetEmbedURLFromProps()
      //setOpenNewURLAMI("routestatus")
    }  else if (location.pathname === "/assets" || location.pathname === "/assets/") {
      setSelectedIndex(8);
      setPageGroupName("Delivery Insights");
      setPageTitle("Assets");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
      //setOpenNewURLAMI("assets")
    }  else if (location.pathname === "/lifts") {
      setSelectedIndex(9);
      setPageGroupName("Delivery Insights");
      setPageTitle("Lifts");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
      //setOpenNewURLAMI("lifts")
    }  else if (location.pathname === "/casesperhour") {
      setSelectedIndex(10);
      setPageGroupName("Delivery Insights");
      setPageTitle("Cases Per Hour");
      setStartRefreshFromProps(false)
      setStartDockDoorRefreshFromProps(false)
      setResetEmbedURLFromProps()
      //setOpenNewURLAMI("casesperhour")
    }
    if (location.pathname.includes("/auditpallet")) {
      setPageSubTitleFromProps("Audit Pallet");


    }


    return () => {
      // Unbind the event listener on clean up
      // window.removeEventListener("message",onMessageReceive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);



  let setCookies = async ()=>{
    // let token = await getToken();
    const userToken = {
      token: await getToken(),
      userId: await getCentralAdminId(),
    };
    console.log("Toekn:"+userToken.token);
    console.log("Central admin Id:"+userToken.userId)
    Cookies.set("authToken", userToken.token);//, { path: '/dashboard', domain: AMI_API_ENDPOINT })
    Cookies.set("loggedInUserId", userToken.userId);//, { domain: AMI_API_ENDPOINT })
  }

  let getCentralAdminId = async () => {
    /*const loggedInUserId = Cookies.get('loggedInUserId');
    if (loggedInUserId) {
      return loggedInUserId
    } else {
      const user = await Auth.currentUserInfo();
      if (user && user.attributes) {
        return user.attributes['custom:central_user_id'];
      }
    }*/
    const user = await Auth.currentUserInfo();
    if (user && user.attributes) {
      return user.attributes['custom:central_user_id'];
    }
    return "";
  }

  return (
      <Box sx={{ display: "flex" }}>
        {
          isUserPermisisons?"":<ShowModal />
        }

        <AppBar
            position="fixed"
            open={open}
            sx={{
              backgroundColor: "#FBFBFB",
              boxShadow: "none",
            }}
        >
          <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon
                  sx={{
                    color: "black",
                  }}
              />
            </IconButton>
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
            >
              <Box>
                <Box sx={{textAlign:"center"}}>
                  {isProgess?<LoadingBar /> :""}
                </Box>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                  <Typography
                      variant="body1"
                      color="#939598"
                      sx={{ fontWeight: 400 }}
                  >
                    {pageGroupName}
                  </Typography>
                  <ButtonBase
                      disabled={!pageSubTitleFromProps}
                      onClick={() => {
                        if(pageTitle == "Pallet Review") {
                          navigate('/qcbot');
                        }else{
                          navigate(-1);
                        }


                        setPageSubTitleFromProps("");
                        setVorReferenceNumberFromProps("");
                      }}
                  >
                    <Typography
                        variant="body1"
                        color={pageSubTitleFromProps ? "#939598" : "#58595B"}
                        sx={{ fontWeight: 400 }}
                    >
                      {pageTitle}
                    </Typography>
                  </ButtonBase>
                  {pageSubTitleFromProps && (
                      <Typography
                          variant="body1"
                          color="#58595B"
                          sx={{ fontWeight: 400 }}
                      >
                        {pageSubTitleFromProps}
                      </Typography>
                  )}
                </Breadcrumbs>

                {location.pathname !== "/vor-gpt" && (
                    <>
                      {pageSubTitleFromProps ? (
                          <>
                            <Box sx={{ mt: "8px" }} />
                            <Typography variant="h2" color="#58595B">
                              {palletDetailsFromProps?.store_name} - Pallet&nbsp;
                              {palletDetailsFromProps?.customer_picklist_id}
                            </Typography>
                          </>
                      ) : (
                          <>
                            <Box sx={{ mt: "8px",display:"none" }} />
                            <Typography variant="h5" color="#58595B" sx={{display:"none"}}>
                              {pageTitle}
                            </Typography>
                          </>
                      )}
                    </>
                )}
              </Box>
              {pageSubTitleFromProps && (
                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                  >
                    <Typography
                        variant="body1"
                        color="#58595B"
                        align="right"
                        sx={{ fontWeight: 400 }}
                    >
                      {palletDetailsFromProps?.customer_picklist_id} -{" "}
                      {palletDetailsFromProps?.operator_name}
                    </Typography>
                    {palletDetailsFromProps?.contains_un_trained_sku && (
                        <Chip
                            icon={<ErrorIcon />}
                            variant="outlined"
                            color={"error"}
                            label={
                              <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                              >
                                Contains untrained SKU
                              </Typography>
                            }
                            size="small"
                        />
                    )}
                    {palletDetailsFromProps?.is_irregular_weight && (
                        <Chip
                            icon={<ErrorIcon />}
                            variant="outlined"
                            color={"error"}
                            label={
                              <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                              >
                                Irregular weight, please review
                              </Typography>
                            }
                            size="small"
                        />
                    )}
                    {palletDetailsFromProps?.contains_un_trained_sku &&
                        palletDetailsFromProps?.is_weight_heuristic_applied && (
                            <Chip
                                icon={<ErrorIcon />}
                                variant="outlined"
                                color={"error"}
                                label={
                                  <Typography
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: "13px",
                                      }}
                                  >
                                    Please confirm verified pallet
                                  </Typography>
                                }
                                size="small"
                            />
                        )}
                    {palletDetailsFromProps?.is_system_error === 1 && (
                        <Chip
                            icon={<ErrorIcon />}
                            variant="outlined"
                            color={"error"}
                            label={
                              <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                              >
                                Audit needed due to system error
                              </Typography>
                            }
                            size="small"
                        />
                    )}
                    {palletDetailsFromProps?.is_picker_err !== null && (
                        <Chip
                            variant="outlined"
                            color={"primary"}
                            label={
                              <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                              >
                                Audited
                              </Typography>
                            }
                            size="small"
                            sx={{
                              marginTop: "5px",
                            }}
                        />
                    )}
                  </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
            sx={{
              width: isTabletDevice() ? "100%":drawerWidth,
              [theme.breakpoints.down("md")]: {
                width: 0,
              },
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: isTabletDevice() ? "100%":drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
        >
          <DrawerHeader>
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  marginY: "20px",
                }}
            >
              <IconButton aria-label="logo" onClick={() => navigate("/")}>
                <img src={AppLogo} alt="logo" style={{ marginLeft: "10px" }} />
              </IconButton>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                ) : (
                    <ChevronRightIcon />
                )}
              </IconButton>
            </Box>
          </DrawerHeader>
          <Divider />

          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
          >
            <Box>
              <List>
                <ListItem
                    disablePadding
                    sx={{display:(qcBotVisible?"block":"none")}}
                    onClick={handleQcBotCollapse}
                >
                  <ListItemButton>
                    <ListItemText>
                      <Typography variant="h4" color="#111540">
                        QC Bot
                      </Typography>
                    </ListItemText>
                    {isQcBotCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={isQcBotCollapse} timeout="auto" unmountOnExit sx={{display:(qcBotVisible?"":"none")}}>
                  {qcBotItems.map((item, index) => (
                      <ListItem
                          key={item.id}
                          disablePadding
                          sx={{ display: "block" }}
                      >
                        <ListItemButton
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "#2776CC",
                                color: "#FFFFFF",
                              },
                              "&.MuiListItemButton-root:hover": {
                                backgroundColor: "#2776CC",
                                color: "#FFFFFF",
                              },
                            }}
                            selected={selectedIndex === item.id}
                            onClick={(event) => handleListItemClick(event, item)}
                        >
                          <ListItemText>
                            <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: selectedIndex === item.id && "600",
                                }}
                            >
                              {item.text}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                  ))}
                </Collapse>
                <Box sx={{ mt: "30px" }} />
                <ListItem
                    // key={item.text}
                    disablePadding
                    sx={{ display: (lvVisible?"block":"none") }}
                    onClick={handleLoadValidationCollapse}
                >
                  <ListItemButton>
                    <ListItemText>
                      <Typography variant="h4" color="#111540">
                        Load Validation
                      </Typography>
                    </ListItemText>
                    {isLoadValidationCollapse ? (
                        <ExpandLessIcon />
                    ) : (
                        <ExpandMoreIcon />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                    in={isLoadValidationCollapse}
                    timeout="auto"
                    unmountOnExit
                    sx={{ display: (lvVisible?"":"none") }}
                >
                  {loadValidationItems.map((item, index) => (
                      <ListItem
                          key={item.id}
                          disablePadding
                          sx={{ display: "block" }}
                      >
                        <ListItemButton
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "#2776CC",
                                color: "#FFFFFF",
                              },
                              "&.MuiListItemButton-root:hover": {
                                backgroundColor: "#2776CC",
                                color: "#FFFFFF",
                              },
                            }}
                            selected={selectedIndex === item.id}
                            onClick={(event) => handleListItemClick(event, item)}
                        >
                          <ListItemText>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: selectedIndex === item.id && "600",
                                  }}
                              >
                                {item.text}
                              </Typography>
                              {item.name === "Dock Door Controls" && (
                                  <CircleIcon
                                      sx={{
                                        fontSize: 10,
                                        color: "red",
                                        marginLeft: "10px",
                                      }}
                                  />
                              )}
                            </Box>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                  ))}
                </Collapse>
                <Box sx={{ mt: "30px" }} />
                <ListItem
                    // key={item.text}
                    disablePadding
                    sx={{ display:(skVisible?"block":"none")}}
                    onClick={handleGeneralFeaturesCollapse}
                >
                  <ListItemButton>
                    <ListItemText>
                      <Typography variant="h4" color="#111540">
                        Warehouse Insights
                      </Typography>
                    </ListItemText>
                    {isWarehouseInsightsCollapse ? (
                        <ExpandLessIcon />
                    ) : (
                        <ExpandMoreIcon />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                    in={isWarehouseInsightsCollapse}
                    timeout="auto"
                    unmountOnExit
                    sx={{ display:(skVisible?"":"none")}}
                >
                  {warehouseInsightItems.map((item, index) => (
                      <ListItem
                          key={item.id}
                          disablePadding
                          sx={{
                            selected: {
                              backgroundColor: "red",
                            },
                          }}
                      >
                        <ListItemButton
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "#2776CC",
                                color: "#FFFFFF",
                              },
                              "&.MuiListItemButton-root:hover": {
                                backgroundColor: "#2776CC",
                                color: "#FFFFFF",
                              },
                            }}
                            selected={selectedIndex === item.id}
                            onClick={(event) => handleListItemClick(event, item)}
                        >
                          <ListItemText>
                            <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: selectedIndex === item.id && "600",
                                }}
                            >
                              {item.text}
                            </Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                  ))}
                </Collapse>

                <Box sx={{ mt: "30px" }} />
                <ListItem
                    // key={item.text}
                    disablePadding
                    sx={{ display:(diVisible?"block":"none")}}
                    onClick={handleDeliveryCollapse}
                >
                  <ListItemButton>
                    <ListItemText>
                      <Typography variant="h4" color="#111540">
                        Delivery Insights
                      </Typography>
                    </ListItemText>
                    {isDeliveryInsightsCollapse ? (
                        <ExpandLessIcon />
                    ) : (
                        <ExpandMoreIcon />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                    in={isDeliveryInsightsCollapse}
                    timeout="auto"
                    unmountOnExit
                >
                  {deliveryInsights.map((item, index) => (


                      (item.visible ? (<ListItem
                              key={item.id}
                              disablePadding
                              sx={{
                                selected: {
                                  backgroundColor: "red",
                                },

                              }}
                          >
                            <ListItemButton
                                sx={{
                                  "&.Mui-selected": {
                                    backgroundColor: "#2776CC",
                                    color: "#FFFFFF",
                                  },
                                  "&.MuiListItemButton-root:hover": {
                                    backgroundColor: "#2776CC",
                                    color: "#FFFFFF",
                                  },
                                }}
                                selected={selectedIndex === item.id}
                                onClick={(event) => handleListItemClick(event, item)}
                            >
                              <ListItemText>
                                <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: selectedIndex === item.id && "600",
                                    }}
                                >
                                  {item.text}
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>) : ("")
                      )
                  ))}
                </Collapse>


              </List>
            </Box>
            <Box>
              <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "20px",
                    marginBottom: "10px",
                  }}
              >
                <IconButton
                    onClick={() => {
                      //localStorage.removeItem(SESSION_KEY);
                      navigate("/");
                      setCustomersFromProps({});
                      setSelectedCustomerFromProps({});
                      setSelectedDistributionCenterFromProps({});
                      setLoadingPalletsDataFromProps(false);
                      setPalletsDataFromProps({});
                      setPalletInferenceIDFromProps("");
                      setPalletDetailsFromProps({});
                      setPageSubTitleFromProps("");
                      setVorReferenceNumberFromProps("");

                      setPalletArchiveDataFromProps({});
                      setLoadingPalletsArchiveDataFromProps(false);


                      Auth.signOut();
                      setTimeout(()=>{
                        //  window.location.reload();
                        let newloc = window.location.origin;
                        window.location.href = newloc;
                      },2000)

                    }}
                    aria-label="signOut"
                >
                  <LogoutIcon
                      sx={{
                        color: "#111540",
                      }}
                  />
                </IconButton>
              </Box>
              <Divider />
              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginY: "20px",
                  }}
              >
                <img src={RehrigLogo} alt="rehrig-logo" />
              </Box>
            </Box>
          </Box>
        </Drawer>

        <Main open={open}>
          {location.pathname !== "/vor-gpt" ? (
              <DrawerHeader />
          ) : (
              <Box sx={{ mt: "40px" }} />
          )}
          {children}
        </Main>
      </Box>
  );
}

const sideNavDrawerStateToProps = (state) => {
  return {
    palletDetailsFromProps: state?.palletReview?.palletDetails,
    pageSubTitleFromProps: state?.palletReview?.pageSubTitle,
  };
};

const sideNavDrawerDispatchToProps = (dispatch) => {
  return {
    checkAccessFromProps: (value) => dispatch(checkAccess()),
    setPageSubTitleFromProps: (value) => dispatch(setPageSubTitle(value)),
    setVorReferenceNumberFromProps: (data) =>
        dispatch(setVorReferenceNumber(data)),
    setCustomersFromProps: (data) => dispatch(setCustomers(data)),
    setSelectedCustomerFromProps: (data) => dispatch(setSelectedCustomer(data)),

    setSelectedDistributionCenterFromProps: (data) =>
        dispatch(setSelectedDistributionCenter(data)),
    setLoadingPalletsDataFromProps: (data) =>
        dispatch(setLoadingPalletsData(data)),
    setPalletsDataFromProps: (data) => dispatch(setPalletsData(data)),
    setPalletInferenceIDFromProps: (data) =>
        dispatch(setPalletInferenceID(data)),
    setPalletDetailsFromProps: (data) => dispatch(setPalletDetails(data)),

    setPalletArchiveDataFromProps: (data) =>
        dispatch(setPalletArchiveData(data)),
    setLoadingPalletsArchiveDataFromProps: (data) =>
        dispatch(setLoadingPalletsArchiveData(data)),
    setStartRefreshFromProps: (value) => dispatch(setStartRefresh(value)),
    setStartDockDoorRefreshFromProps: (value) => dispatch(setStartDockDoorRefresh(value)),
    setResetEmbedURLFromProps: () => dispatch(resetEmbedURL()),
    setOpenNewURLAMI: (value) => dispatch(openNewURLAMI(value)),
    getUserDetails: (value) => dispatch(getUserDetails(value)),
  };
};

export default connect(
    sideNavDrawerStateToProps,
    sideNavDrawerDispatchToProps
)(SideNavDrawer);


