import React, { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";

import AuthFlow from "./AuthFlow";
import {
  // selectAuthStatus,
  setAuthStatus,
} from "../../redux/reducers/authentication/AuthReducer";

function AppAuth({ children, setAuthStatusFromProps }) {
  // const dispatch = useDispatch();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // console.log("selectAuthStatus", useSelector(selectAuthStatus));

  useEffect(() => {
    //console.log("authStatus", authStatus);
    setAuthStatusFromProps(authStatus);
    //dispatch(setAuthStatus(authStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  async function getUsername() {
    const username = await Auth.currentUserInfo();
    console.log("username", username?.username);
    // dispatch(setUsername(username));
  }
  if (authStatus === "configuring") {
    getUsername();
  }

  if (authStatus === "authenticated") {
    getUsername();
    return <>{children}</>;
  } else if (authStatus === "configuring") {
    return <></>;
  } else {
    return <AuthFlow />;
  }
}

const authStateToProps = (state) => {
  // console.log(state.auth.authStatus);
  return {
    authState: state.auth.authStatus,
  };
};

const authStatusDispatchToProps = (dispatch) => {
  return {
    setAuthStatusFromProps: (authStatus) => dispatch(setAuthStatus(authStatus)),
  };
};

export default connect(authStateToProps, authStatusDispatchToProps)(AppAuth);
